import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Header from "../../components/header"
import Footer from "../../components/footer"
import Content from "../../components/content"
import SEO from "../../components/seo"
import Img from "gatsby-image"
import showdown from 'showdown'

const BoardPage = ({data}) => {
    let directors = data.graphAPI.directors
    return (
        <Layout>
            <SEO title="Board and Consultants" />
            <div className="title-section">
                <h1 className="title is-size-1">Board of Directors</h1>
            </div>
            <div className="content-section">
            {directors.map(director => {
                let converter = new showdown.Converter()
                let body = converter.makeHtml(director.content)
                return ( <div key={director.id}>
                    <div className="card media card-stylized">
                        <div className="media-left">
                            <figure className="image is-rounded">
                                {director.image !== null ? (
                                    <Img fluid={director.image.documentFile.childImageSharp.fluid} className="image is-128x128" alt="" />
                                ) : (<></>)}
                            </figure>
                        </div>
                        <div className="media-content" style={{padding: '2rem', borderLeft: '1px solid rgb(205,203,177)', marginLeft: '-1rem'}}>
                            <p  className=""><span style={{textTransform: 'uppercase'}}>{director.Role}</span><br /><strong className="is-size-4">{director.Name}</strong><br /><em><small className="has-text-grey">Term: <span style={{ fontSize: '0.8rem' }}>{director.Term}</span></small></em></p>
                            {/* <p  className=""><span style={{textTransform: 'uppercase'}}>{director.Role}</span><br /><strong className="is-size-4">{director.Name}</strong><br /><em><small className="has-text-grey">Elected</small></em></p> */}
                            <div className="" dangerouslySetInnerHTML={{__html: body}}></div>
                        </div>
                    </div>
                </div>
            )})}
            </div>
            <Content content={data.graphAPI.contentDirector.content} />
            <div className="title-section">
                <h1 className="title is-size-1">Consultants and Vendors</h1>
            </div>
            <div className="content-align consultant-grid" style={{marginBottom: '3rem'}}>
            {data.graphAPI.consultants.map(consultant => (
                <div className="item" key={consultant.id}>
                    <span>
                    <small style={{textTransform: 'uppercase'}}>{consultant.role}</small>
                    <p className="is-size-5">{consultant.company}</p>
                    </span>
                    <p>{consultant.addressLine1}<br />{consultant.addressLine2}</p>
                    { consultant.website ? (<a href={consultant.website} target="_blank" rel="noopener noreferrer">Website</a>) : (<></>)}
                </div>
            ))}
            </div>
        </Layout>
    )
}

export default BoardPage

export const query = graphql`
    query boardPageById {
        graphAPI {
            directors (sort: "order:asc") {
                id
                Name
                Role
                Term
                order
                content
                image {
                    id
                    updated_at
                    url
                    documentFile {
                        childImageSharp {
                            fluid(maxWidth: 512){
                                ...GatsbyImageSharpFluid_tracedSVG
                            }
                        }
                    }
                }
            }
            contentDirector {
                content {
                    ... on GRAPHAPI_ComponentPageBaseContent {
                        ...baseContent
                    }
                    ... on GRAPHAPI_ComponentPageImage {
                        ...pageImage
                    }
                    ... on GRAPHAPI_ComponentPageLinkCardWithImage {
                        ...linkCardWithImage
                    }
                    ... on GRAPHAPI_ComponentPageSplitContentImage {
                        ...splitContentImage
                    }
                    ... on GRAPHAPI_ComponentPageGallery {
                        ...pageGallery
                    }
                    ... on GRAPHAPI_ComponentPageLinkList {
                        ...pageLinkList
                    }
                }
            }
            consultants(sort: "role:asc") {
                id
                role
                company
                addressLine1
                addressLine2
                website
            }
        } 
    }
`


